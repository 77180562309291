import $ from 'jquery';
import { checkLogin, LoginResult } from 'JIX/utils/login.js';
import { bootboxConfirm } from 'JIX/utils/bootbox.js';
import { deleteMyjob, listMyjob } from 'JIX/myjob/api.js';
import getToastManager from 'JIX/ui/toasts.js';

import { __, __x, __nx, __N } from 'JIX/gettext.js';


async function confirmWhenMyjob() {
    return await bootboxConfirm({
        title: __('Jobbet slettes fra \'Gemte job\''),
        message: '<p>' + __('Du har tidligere gemt dette job. Hvis du fortsætter slettes det fra gemte job.') + '</p>',
        buttons: {
            confirm: {
                label: __('Fortsæt'),
            },
            cancel: {
                label: __('Fortryd'),
            }
        },
    });
}


const notInterestedJobStates = Object.freeze({
    SELECTED: 'selected',
    UNSELECTED: 'unselected',
});

const buildUrl = (tid, queryParams) => {
    const queryString = new URLSearchParams(queryParams).toString();
    return `/api/not-interested-job/v1/${tid}?${queryString}`;
};

async function callApi(tid, method, queryParams = {}, toastErrorMessage = '') { // method should be post or delete
    try {
        const response = await fetch(buildUrl(tid, queryParams), {
            method,
            headers: { 'X-CSRF-Token': window.Stash.common.csrf_token },
        });
        const json = await response.json();
        return json.html;
    } catch (e) {
        if (toastErrorMessage) {
            getToastManager().createToast({
                title: __('Der skete en fejl'),
                message: toastErrorMessage,
                type: 'danger',
            }).show();
        }
    }
}

async function addNotInterestedJob(tid, queryParams) {
    return await callApi(tid, 'POST', queryParams, __('Jobbet kunne markeres som "ikke interessant". Prøv igen'));
}


async function removeNotInterestedJob(tid, queryParams) {
    return await callApi(tid, 'DELETE', queryParams, __('Markeringen af jobbet som "ikke interessant" kunne ikke fjernes. Prøv igen'));
}

export default function() {
    const ELEMENT_SELECTOR = ".jobad-element-not-interested-job";
    const onClick = async (element, event) => {
        event.preventDefault();
        const loginStatus = await checkLogin();
        if (loginStatus === LoginResult.ABORTED || loginStatus === LoginResult.UNKNOWN) {
            return;
        }

        const userLoggedIn = loginStatus === LoginResult.LOGGED_IN;
        const tid = element.dataset.tid;
        const state = element.dataset.state;
        const jobadWrapper = document.getElementById(`jobad-wrapper-${tid}`);

        const checkIfMyjob = async (tid) => {
            try {
                const myjobList = await listMyjob();
                return { isMyjob: Object.keys(myjobList.jobads).includes(tid) };
            } catch (e) {
                return { errorMessage: __('Der skete en fejl. Prøv igen') };
            }
        };

        const myjobCheck = await checkIfMyjob(tid);
        if (myjobCheck.errorMessage) {
            getToastManager().createToast({
                title: __('Der skete en fejl'),
                message: myjobCheck.errorMessage,
                type: 'danger',
            }).show();
            return;
        }

        if (myjobCheck.isMyjob) {

            const confirm = await confirmWhenMyjob();
            if (!confirm) {
                if (userLoggedIn) {
                    window.location.reload();
                }
                return;
            }
            try {
                await deleteMyjob({ tid });
            } catch (e) {
                getToastManager().createToast({
                    title: __('Der skete en fejl'),
                    message: __('Jobbet kunne ikke fjernes fra gemte job. Prøv igen'),
                    type: 'danger',
                }).show();
                return;
            }
        }

        const queryParams = {
            include_html: 1,
            longitude: jobadWrapper.dataset.longitude ?? '',
            latitude: jobadWrapper.dataset.latitude ?? '',
        };

        const html = state === notInterestedJobStates.UNSELECTED ? await addNotInterestedJob(tid, { include_html: 1 }) : await removeNotInterestedJob(tid, queryParams);
        if (!html) {
            if (userLoggedIn) {
                window.location.reload();
            }
            // For the situation where an error occur in the API call
            // Or the action has no effect, e.g. if the job is already marked as a not interested job
            // and the user tries to do it again because he/she is not logged in, or has multiple tabs open.
            return;
        }

        // If the user used this as an opportunity to log in, we need to reload the page
        if (userLoggedIn) {
            window.location.reload();
        } else {
            // else we update the changes manually.
            jobadWrapper.innerHTML = html;
            const e = new CustomEvent("reloadJobads", { detail: { jobadWrapper } });
            window.dispatchEvent(e);
        }
    };
    $(document).on('click', ELEMENT_SELECTOR, (event) => {
        onClick(event.currentTarget, event);
    });
}
